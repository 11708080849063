<template>
  <div class="app-bar-sticky">
    <div id="home-app-bar">
      <img
        src="@/assets/logo_dark.svg"
        @click="$router.replace('/')"
        alt="Logo"
        id="logo"
      />

      <v-tabs class="hidden-sm-and-down" optional bg-color="transparent">
        <v-tab
          mandatory
          color="indigo"
          center-active
          v-for="(name, i) in items"
          :key="i"
          :to="{ name }"
          :exact="name === 'Home'"
          :ripple="false"
          class
          text
          >{{ name }}</v-tab
        >
      </v-tabs>

      <span id="home-app-bar-container">
        <a href="/seja-socio" id="associate_button" class="hidden-sm-and-down header-icon">
          <img src="@/assets/pen.png" alt="Icone de Associe-se" />
          Seja <br/> Sócio
        </a>

        <a href="http://carteira.bancariosuberlandia.org.br" target="_blank" id="covenant_button" class="hidden-sm-and-down header-icon">
          <img src="@/assets/id-card.png" alt="Icone de carteira" />
          <span class="hidden-sm-and-down">
            Carteira <br/> Convênio
          </span>
        </a>

        <!-- <a href="/login" id="login_button" class="header-icon">
          <img src="@/assets/user_circle.svg" alt="Icone de Login" />
          Login
        </a> -->

        <a href="https://wa.me/553432367277" target="_blank" id="whatsapp_button" class="header-icon">
          <img src="@/assets/logo-whatsapp.png" alt="Icone do whatsapp" />
          <span class="hidden-sm-and-down">
            Fale <br/> Conosco
          </span>
        </a>

        <v-app-bar-nav-icon
          class="hidden-md-and-up blue--text"
          @click="drawer = !drawer"
        />
      </span>

      <drawer v-model="drawer" :items="itemsMobile" />

    </div>
  </div>
</template>

<script>
export default {
  name: "AppBar",
  components: {
    Drawer: () => import("./Drawer"),
  },

  data: () => {
    const items = [
      "Home",
      "Acordos e Convenções",
      "Notícias",
      "Galeria",
      "Clube",
      "Convênios",
      "Contato",
    ]

    const itemsMobile = [
      ...items,
      ...[
        "Seja sócio",
        "Carteira Convênio"
      ]
    ]

    return {
      drawer: null,
      items: items,
      itemsMobile: itemsMobile,
    }
  },
};
</script>

<style lang="scss">
.app-bar-sticky {
  border-bottom-left-radius: 50%;
  border-bottom-right-radius: 50%;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
}

#logo {
  cursor: pointer;
}

#home-app-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 140px;
  padding-bottom: 30px;

  #home-app-bar-container {
    display: flex;
    justify-content: space-between;
  }

  #home-app-bar-container a {
    margin-right: 15px;
  }

  .v-tabs {
    height: 32px;
    flex: none;
    width: auto;
  }
  .v-tab {
    height: 32px;
    padding: 5px 8px;
    margin-right: 13px;
    color: #334666;
    font-size: 18px;
    text-transform: none;
  }
  .v-tab:hover,
  .v-tab--active {
    color: white;
    background-color: #1c8be3;
    border-radius: 5px;
  }
  .v-tabs-slider-wrapper {
    display: none;
  }

  .v-tabs-bar__content {
    background-color: #fbfbfb;
  }

  .header-icon {
    text-decoration: none;
    color: #334666;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 12px;
    line-height: 15px;
    text-align: center;
  }

  .header-icon img {
    margin-bottom: 5px;
    width: 30px;
  }

  @media screen and (min-width: 1400px) {
    max-width: 1230px;
    margin: 0 auto;
    padding: 10px 0px;
    padding-bottom: 30px;
  }

  @media screen and (max-width: 1300px) {
    #logo {
      width: 70px;
    }
    .v-tab {
      min-width: 60px;
      font-size: 16px;
    }
    #login_button {
      font-size: 14px;
    }
  }
  @media screen and (max-width: 1200px) {
    padding: 15px 80px;
    padding-bottom: 30px;

    .v-tab {
      margin-right: 08px;
      font-size: 14px;
      padding: 3px 6px;
    }
    #login_button {
      font-size: 12px;

      img {
        width: 25px;
      }
    }
  }

  @media screen and (max-width: 959.2px) {
    padding: 15px 100px;
    padding-bottom: 20px;
  }
}
</style>
